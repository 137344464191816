/* Countdown.css */
.countdown-wrapper {
    text-align: center;
    padding: 2rem;
    background-color: #f0f4f8; /* Light background */
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .countdown-timer {
    font-size: 2.5rem;
    font-weight: bold;
    color: #3a3a8c; /* c3vision theme: blue-purple */
  }
  
  .countdown-date {
    font-size: 1rem;
    margin-top: 0.5rem;
    color: #444;
  }
  
  /* Responsive tweaks */
  @media (max-width: 600px) {
    .countdown-timer {
      font-size: 1.8rem;
    }
  
    .countdown-date {
      font-size: 0.9rem;
    }
  
    .countdown-wrapper {
      padding: 1.5rem;
    }
  }
  .countdown-title {
    margin-bottom: 1rem;
    color: #3a3a8c;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  
  