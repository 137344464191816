/* General navbar styles */
.custom-navbar {
  background-color: #E0E0E0;
}

/* Custom button styles */
.custom-btn {
  background-color: #1520bf;
  border-color: #1520bf;
}

/* Styles for small screens (mobile) */
@media (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #E0E0E0;
    padding: 1rem;
    z-index: 1000;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .nav-item {
    margin-bottom: 0.5rem;
  }

  /* Center the logo on small screens */
  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Ensure the button stays on the right */
  .custom-btn {
    margin-left: auto;
  }
}

/* Styles for large screens (desktop) */
@media (min-width: 992px) {
  .navbar-brand {
    margin-right: 1rem;
  }

  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-nav {
    flex-direction: row;
    margin-left: 1rem;
  }

  .nav-item {
    margin-right: 1rem;
  }

  /* Ensure proper alignment of navbar items */
  .navbar > .container {
    display: flex;
    align-items: center;
  }

  /* Push the button to the right */
  .custom-btn {
    margin-left: auto;
  }
}

/* Active link styles */
.nav-link.active {
  font-weight: bold;
}

/* Additional styles for better spacing and alignment */
.navbar-toggler {
  border: none;
  padding: 0;
}

.navbar-brand img {
  max-height: 50px;
  width: auto;
}

.palestine-ribbon {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  border-left: 40px solid transparent;
  border-bottom: 40px solid #e11d48; /* red for Palestine */
}

/* Layout.css */

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.palestine-banner {
  background-color: #e11d48;
  color: white;
  padding: 10px 16px;
  font-size: 0.95rem;
  font-weight: 500;
}

.palestine-banner-inner {
  display: flex;
  justify-content: center;
}

.palestine-banner-content {
  display: flex;
  align-items: center;
  justify-content: center; /* Add this */
  gap: 10px;
  max-width: 900px;
  width: 100%;
}

.palestine-banner-content img {
  height: 20px;
  flex-shrink: 0;
}

.palestine-banner-content p {
  margin: 0;
  text-align: left;
  line-height: 1.4;
  flex: 0 1 auto; /* Change from flex: 1 */
}

@media (max-width: 600px) {
  .palestine-banner-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
